import React, { FC } from 'react'
import { Wrapper } from 'src/components/Common/SocialMendia/styles'
import Link from 'next/link'

interface Props {
  visibleInMobile?: boolean
  applyAlignMobile?: string
  applyAlignDesktop?: string
}

const MenuSocialMedia: FC<Props> = props => {
  const {
    visibleInMobile = false,
    applyAlignMobile = 'center',
    applyAlignDesktop = 'start',
  } = props
  return (
    <Wrapper
      visibleInMobile={visibleInMobile}
      applyAlignMobile={applyAlignMobile}
      applyAlignDesktop={applyAlignDesktop}
    >
      <ul id="menu-social-media">
        <li>
          <Link legacyBehavior passHref href="https://www.facebook.com/CordasEMusica/">
            <a target="_blank">
              <img
                src="https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224598/facebook_kklkm0.png"
                alt="logo do facebook"
              />
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior passHref href="https://twitter.com/cordasemusica">
            <a target="_blank">
              <img
                src="https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224598/twitter_urrty2.png"
                alt="logo do twitter"
              />
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior passHref href="https://www.youtube.com/cordasemusica">
            <a target="_blank">
              <img
                src="https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224598/youtube_v66t93.png"
                alt="logo do youtube"
              />
            </a>
          </Link>
        </li>
      </ul>
    </Wrapper>
  )
}

export default React.memo(MenuSocialMedia)
