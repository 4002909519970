import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const LogoBackground = styled.section`
  background-image: url('https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224794/fundo-professores_btikhn.png');
  background-attachment: fixed;
  background-position: 50% 100%;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    padding-bottom: ${p => p.theme.spacing.large};
  }

  @media ${device.tablet} {
    background-position: 50% 10%;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'social_media logo group_button';
  }

  .nav {
    display: none;
    @media ${device.tablet} {
      display: block;
      grid-area: social_media;
    }
  }

  .group-buttons {
    grid-area: group_button;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.tablet} {
      justify-content: flex-end;
    }

    a + a {
      margin-left: 0.5rem;
    }

    a {
      div {
        ${({ theme }) => ({ ...theme.typography.primary.semiBold })};
        span {
          ${({ theme }) => ({ ...theme.size.xs })};
        }
      }

      :hover {
        filter: brightness(90%);
      }
    }
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: logo;
`

export const Logo = styled.img`
  margin-bottom: 1rem;
  max-width: 190px;
`
