import React, { FC } from 'react'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import useGetProfile from 'src/hooks/useGetProfile'
import { CategoriesApi, CategoriesWithArea } from 'src/service/api/api.types'

interface Props {
  showCategory?: boolean
  categories?: CategoriesApi
  children: React.ReactNode
  customCategories?: Record<string, CategoriesWithArea>
}

const PublicLayout: FC<Props> = ({ children, showCategory, categories, customCategories }) => {
  useGetProfile()

  return (
    <>
      <Header
        categories={categories}
        customCategories={customCategories}
        showCategory={showCategory}
      />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default PublicLayout
