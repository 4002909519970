import React, { FC } from 'react'
import styled from 'styled-components'
import MenuSocialMedia from 'src/components/Common/SocialMendia'
import { Button } from 'src/components/Common/Button'
import Link from 'next/link'
import { ImExit } from 'react-icons/im'
import { FaMusic, FaUserAlt } from 'react-icons/fa'
import GroupButtonsComponent from 'src/components/Common/GroupButtons'
import { CategoriesApi, CategoriesWithArea } from 'src/service/api/api.types'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/store/rootReducers'
import { logout } from 'src/store/user'
import MenuSticky from 'src/components/Common/MenuSticky'
import { Logo, LogoBackground, LogoWrapper } from './styles'
import { CategoryMenu } from '../Common/CategoryMenu'
import Container from '../Common/Container'

interface Props {
  showCategory?: boolean
  categories?: CategoriesApi
  customCategories?: Record<string, CategoriesWithArea>
}

const HeaderWrapper = styled.header``

const Header: FC<Props> = props => {
  const { showCategory = true } = props
  const dispatch = useDispatch()
  const profile = useSelector((state: State) => state.user.profile)
  const isAuthenticated = useSelector((state: State) => state.user.isAuthenticated)

  const buttonsLogger = () => {
    if (profile) {
      return (
        <div>
          <Button
            type="button"
            isLink
            href="/perfil"
            labelTx="header.Profile"
            preset="secondary"
            LeftAccessory={<FaUserAlt />}
          />
          <Button
            type="button"
            onClick={() => dispatch(logout())}
            labelTx="header.exit"
            preset="green"
            LeftAccessory={<ImExit />}
          />
        </div>
      )
    }
  }

  return (
    <HeaderWrapper>
      <MenuSticky />

      <Container className="padding-top">
        <LogoBackground>
          <nav className="nav">
            <MenuSocialMedia />
          </nav>

          <LogoWrapper>
            <Link legacyBehavior passHref href="/">
              <a>
                <Logo
                  src="https://res.cloudinary.com/cordasemusica/image/upload/v1678224794/logo_tk296u.gif"
                  alt="Logo Cordas e Música Projeto Musical"
                />
              </a>
            </Link>
          </LogoWrapper>

          <GroupButtonsComponent gridArea="group_button">
            {isAuthenticated === false ? (
              <>
                <Button
                  type="button"
                  isLink
                  href="/assinar"
                  labelTx="header.register"
                  preset="danger"
                  LeftAccessory={<FaMusic className="icon-login" />}
                />
                <Button
                  type="button"
                  isLink
                  href="/login"
                  labelTx="header.login"
                  preset="secondary"
                  LeftAccessory={<FaUserAlt />}
                />
              </>
            ) : (
              buttonsLogger()
            )}
          </GroupButtonsComponent>
        </LogoBackground>
      </Container>

      {showCategory && (
        <CategoryMenu categories={props.categories} customCategories={props.customCategories} />
      )}
    </HeaderWrapper>
  )
}

export default Header
