import styled from 'styled-components'
import { device } from 'src/theme/theme'

export interface WrapperInput {
  color: string
  padding: string
  borderColor: string
  borderRadius: string
  backgroundColor: string
}

export const Form = styled.form`
  height: 100%;
  display: flex;
  align-items: center;
`

export const BoxSearch = styled.div<WrapperInput>`
  flex: 1;
  display: flex;

  input {
    ${({ theme }) => ({ ...theme.size.xs })};
    width: 100%;
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius};
    color: ${({ color }) => color};
    border: 1px solid ${({ borderColor }) => borderColor};
    background: ${({ backgroundColor }) => backgroundColor};
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;

    input {
      flex: 1;
      width: auto;
      height: 40px;
      text-align: center;
    }
  }
`

export const SearchButton = styled.button`
  &.buttonHeader {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    border-radius: 0.3rem;
    ${({ theme }) => ({ ...theme.size.xs })};
    ${({ theme }) => ({ ...theme.typography.primary.normal })};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.primaryBackgroundColor};
    border-right-width: 0;

    @media ${device.tablet} {
      padding: 0.5rem 1.5rem;
    }

    @media ${device.laptopL} {
      padding: 0.5rem 1.5rem;
    }
  }

  &.buttonSticky {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    border-radius: 0 0.3rem 0.3rem 0;
    ${({ theme }) => ({ ...theme.size.sm })};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.primaryBackgroundColor};
    border-right-width: 0;

    @media ${device.tablet} {
      padding: 0.5rem;
    }

    @media ${device.laptopL} {
      padding: 0.5rem 1rem;
    }
  }

  span {
    ${({ theme }) => ({ ...theme.typography.primary.normal })};
    display: inline-block;
  }

  .icon {
    margin-left: 0;
  }

  :hover {
    filter: brightness(90%);
  }
`
