import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const MenuHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  @media (max-width: 768px) {
    height: 55px;
  }
`

export const Content = styled.div`
  padding: 1rem;

  .logo {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }

  @media ${device.tablet} {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 40% 55%;
    grid-template-areas: 'logo search menu';
    padding: 0;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    grid-template-columns: 1fr 30% 70%;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 45% 46%;
  }

  .button-hamburger {
    position: relative;
    grid-area: menu;
    height: 2.5rem;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0.5rem;
    background-color: white;
    z-index: 3;
    justify-self: end;
    border: 1px solid ${({ theme }) => theme.colors.palette.neutral400};
    > div {
      color: ${p => p.theme.colors.palette.neutral900};
    }
    @media ${device.tablet} {
      visibility: hidden;
    }
  }
  .backdrop {
    display: none;
    position: absolute;
    height: 100vh;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: ${({ theme }) => theme.colors.transparent};
    transition: all 0.2s;
    opacity: 0;
    @media ${device.tablet} {
      visibility: hidden;
    }
  }
  &.mobile {
    .backdrop {
      opacity: 1;
      z-index: 2;
      display: block;
    }
  }

  .group {
    position: absolute;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.palette.white};
    left: -40%;
    top: 0;
    bottom: 0;
    z-index: 3;
    transition: all 0.2s;
    overflow: inherit;

    @media (max-width: 768px) {
      border-right: 1px solid ${p => p.theme.colors.palette.neutral300};
      box-shadow: 1px 1px 16px ${p => p.theme.colors.palette.overlay20};
    }

    @media ${device.tablet} {
      position: ${'static'};
      height: auto;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      background-color: ${({ theme }) => theme.colors.background};
    }

    &.open {
      width: 70%;
      left: -0%;
      border-right: 1px solid ${p => p.theme.colors.palette.neutral300};
    }
    &.close {
      width: 0;
      left: -40%;
      border: 0;
    }

    .logo {
      grid-area: logo;
      grid-column: 1 / 2;
    }
  }
`

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .logo,
    nav {
      width: 100%;
    }

    > :last-child {
      position: absolute;
      top: 10px;
      right: 10px;

      @media ${device.tablet} {
        top: 0;
      }

      @media ${device.laptop} {
        position: ${'static'};
      }
    }
  }
`

export const LogoDesktop = styled.img`
  width: 40px;
  height: auto;
`

export const LogoMobile = styled.img`
  width: 50px;
  height: 60px;
  padding-bottom: 0.3rem;
  display: block;
  margin: 1rem;

  @media ${device.tablet} {
    display: none;
  }
`

export const InputWrapper = styled.div`
  grid-area: search;
  justify-self: center;

  @media (max-width: 768px) {
    display: none;
    background: ${p => p.theme.colors.palette.neutral200};
    width: 100%;
    position: absolute;
    bottom: -60px;
    left: 0;
    padding: 0 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    &.active {
      display: block;
    }
  }

  @media ${device.tablet} {
    justify-self: auto;
  }
`

export const Nav = styled.nav`
  grid-area: menu;
  @media ${device.tablet} {
    display: flex;
    justify-content: flex-end;
  }

  ul {
    margin: 0;
    list-style: none;
    @media ${device.tablet} {
      flex: 1;
      display: flex;
      height: 100%;
      margin-right: 0.5rem;
      align-items: center;
    }
  }

  li + li {
    margin-top: 0.5rem;

    @media ${device.tablet} {
      margin-top: 0;
      margin-left: 0.5rem;
    }
  }

  li {
    display: block;

    @media ${device.tablet} {
      height: 100%;
      display: inline-block;
    }

    &.active {
      a {
        color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
        border-color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
      }
    }

    a {
      height: 100%;
      ${({ theme }) => ({ ...theme.size.xxs })};
      ${({ theme }) => ({ ...theme.typography.primary.medium })};
      margin: 0 1rem;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.primaryTextColor};
      border-bottom: 3px solid transparent;
      transition: all 0.2s;
      padding: 1rem 0;

      @media ${device.tablet} {
        margin: 0 0.5rem;
      }

      :hover {
        color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
        border-color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
      }
    }
  }
`

export const MobileButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > a.whats-app svg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`
