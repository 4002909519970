import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { MdEmail } from 'react-icons/md'
import { FacebookProvider, Page } from 'react-facebook'
import Container from 'src/components/Common/Container'
import useDeviceSize from 'src/hooks/useDeviceSize'
import MenuSocialMedia from 'src/components/Common/SocialMendia'
import MenuList from 'src/components/Common/MenuList'
import { getImageUrl } from 'src/utils/getImageUrl'
import { About, Content, FooterWrapper, MenuFooter, Nav } from './styles'

const Footer: FC = () => {
  const { t } = useTranslation()
  const [width] = useDeviceSize()

  return (
    <FooterWrapper>
      <MenuFooter>
        <Container>
          <Nav>
            <MenuList id="menu-items" />
            <MenuSocialMedia visibleInMobile applyAlignDesktop="end" />
          </Nav>
        </Container>
      </MenuFooter>

      <Container>
        <Content>
          <div className="img-container">
            <img
              src="https://res.cloudinary.com/cordasemusica/image/upload/w_160,h_160,f_webp,c_fill/v1676756168/MarcaVideoClaroo2_h0f5ls.png"
              alt="Logo Cordas e Música Projeto Musical"
            />
          </div>

          <About>
            <h2>{t('footer.title')}</h2>
            <p>{t('footer.text')}</p>
            <div className="link-container">
              <Link legacyBehavior passHref href="mailto:cordasemusica@gmail.com">
                <a>
                  <MdEmail className="icon" /> cordasemusica@gmail.com
                </a>
              </Link>
            </div>
          </About>

          {/* <div className="page">
            <FacebookProvider appId="909081555844971" language="pt-br">
              <Page
                style={{
                  width: width < 375 ? 220 : 'auto',
                }}
                href="https://www.facebook.com/CordasEMusica"
                adaptContainerWidth
              />
            </FacebookProvider>
          </div> */}
        </Content>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
