import React, { FC } from 'react'
import { GroupButtons } from './styles'

interface Props {
  gridArea: string
  children: React.ReactNode
}

const GroupButtonsComponent: FC<Props> = ({ children, gridArea }) => <GroupButtons gridArea={gridArea}>{children}</GroupButtons>
export default GroupButtonsComponent
