import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const FooterWrapper = styled.footer`
  background-image: url('https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224794/fundo-professores_btikhn.png');
  background-attachment: fixed;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding-bottom: ${p => p.theme.spacing.huge};
  }

  @media ${device.tablet} {
    background-position: 50% 170%;
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'logo' 'info' 'page';
  padding: 0.5rem 0;

  @media ${device.tablet} {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'logo page' 'info _';
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas: 'logo info page';
  }

  .img-container {
    grid-area: logo;
    justify-self: center;

    @media ${device.tablet} {
      justify-self: start;
    }
  }

  img {
    width: 160px;
    height: 160px;

    @media ${device.tablet} {
      width: 150px;
      height: 150px;
    }
  }

  .page {
    grid-area: page;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.laptop} {
      align-items: flex-start;
      padding: 10px 0 0;
    }
  }
`

export const MenuFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
`

export const Nav = styled.nav`
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-template-areas: 'social_media' 'menu';
  gap: 1rem;

  @media ${device.tablet} {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'menu social_media';
  }

  ul#menu-items {
    align-items: center;
    list-style: none;
    margin: 0;
  }

  #menu-items {
    display: none;
    @media ${device.tablet} {
      display: flex;
      grid-area: menu;
    }
  }

  li + li {
    margin-left: 0.5rem;
  }

  li {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child a {
      margin-right: 0;
    }

    &.active a,
    a:hover,
    a:focus {
      color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.white};
    }

    a {
      height: 100%;
      ${({ theme }) => ({ ...theme.size.xxs })};
      ${({ theme }) => ({ ...theme.typography.primary.medium })};
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${({ theme }) => theme.colors.white};
      border-bottom: 3px solid transparent;
      transition: all 0.2s;
      padding: 0.4rem 0.5rem;
    }
  }
`

export const About = styled.div`
  grid-area: info;
  padding: 20px 0;
  text-align: center;

  @media ${device.tablet} {
    text-align: left;
    padding: 30px 0;
  }

  @media ${device.laptop} {
    text-align: left;
    padding: 10px 0 30px;
  }

  h2 {
    display: inline-block;
    ${({ theme }) => ({ ...theme.size.lg })};
    color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
    padding: 0 0.5rem;
    :after {
      content: '';
      display: block;
      width: 20%;
      height: 2px;
      margin: ${p => p.theme.spacing.extraSmall} 0 ${p => p.theme.spacing.large};
      background-color: ${({ theme }) => theme.colors.secondaryBackgroundColor};

      @media (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  p {
    padding: 0 0.5rem;
    ${({ theme }) => ({ ...theme.size.xxs })};
    color: ${({ theme }) => theme.colors.primaryTextColor};

    @media ${device.laptop} {
      max-width: 80%;
      ${({ theme }) => ({ ...theme.size.xs })};
    }
  }

  .link-container > a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    ${({ theme }) => ({ ...theme.size.xs })};
    .icon {
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.colors.secondaryTextColor};
    }
    @media ${device.tablet} {
      justify-content: flex-start;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.primaryTextColor};
  }
`
