import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { WhatsAppMenuWrapper } from './MenuList.styles'

interface Props {
  id?: string
}

function MenuList({ id }: Props) {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <>
      <ul id={id}>
        {router.asPath === '/' && (
          <WhatsAppMenuWrapper>
            <Link
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5524988323449&text=Ol%C3%A1,%20acessei%20a%20p%C3%A1gina%20principal%20do%20site%20e%20preciso%20de%20ajuda!"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-label="WhatsApp"
                role="img"
                viewBox="0 0 512 512"
              >
                <rect width="512" height="512" rx="15%" fill="#25d366" />
                <path
                  fill="#25d366"
                  stroke="#ffffff"
                  strokeWidth="26"
                  d="M123 393l14-65a138 138 0 1150 47z"
                />
                <path
                  fill="#ffffff"
                  d="M308 273c-3-2-6-3-9 1l-12 16c-3 2-5 3-9 1-15-8-36-17-54-47-1-4 1-6 3-8l9-14c2-2 1-4 0-6l-12-29c-3-8-6-7-9-7h-8c-2 0-6 1-10 5-22 22-13 53 3 73 3 4 23 40 66 59 32 14 39 12 48 10 11-1 22-10 27-19 1-3 6-16 2-18"
                />
              </svg>
            </Link>
          </WhatsAppMenuWrapper>
        )}
        <li className={router.pathname === '/' ? 'active' : ''}>
          <Link legacyBehavior passHref href="/">
            <a>{t('menu.home')}</a>
          </Link>
        </li>
        <li className={router.pathname === '/faq' ? 'active' : ''}>
          <Link legacyBehavior passHref href="/faq">
            <a>{t('menu.perguntas')}</a>
          </Link>
        </li>
        <li className={router.pathname === '/fale-conosco' ? 'active' : ''}>
          <Link legacyBehavior passHref href="/fale-conosco">
            <a>{t('menu.contato')}</a>
          </Link>
        </li>
      </ul>
    </>
  )
}

export default MenuList
