import styled from "styled-components";
import {device} from "src/theme/theme";

export const Wrapper = styled.div<{
  visibleInMobile: boolean;
  applyAlignMobile: string;
  applyAlignDesktop: string;
}>`
  grid-area: social_media;
  align-self: center;
  visibility: ${({ visibleInMobile }) => visibleInMobile ? 'visible' : 'hidden'};

  @media ${device.tablet} {
    justify-self: ${({ applyAlignDesktop }) => applyAlignDesktop};
    padding: 0;
    visibility: visible;
  }

  ul {
    display: flex;
    justify-self: ${({ applyAlignMobile }) => applyAlignMobile};
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 1rem 0 0;

    @media ${device.tablet} {
      justify-content: start;
      justify-self: ${({ applyAlignDesktop }) => applyAlignDesktop};
      padding: 0;
    }

    li + li {
      margin-left: 0.5rem;
    }

    img {
      cursor: pointer;
      width: 25px;
    }
  }
`;
