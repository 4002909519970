import styled from 'styled-components'

export const Wrapper = styled.div``

export interface BoxProps {
  color: string
  backgroundColor: string
  filter?: string
}

export const Box = styled.div<BoxProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};

  ul li.menu-active a {
    backdrop-filter: brightness(90%);
    text-decoration: none !important;
  }

  a {
    color: ${({ color }) => color} !important;
    :hover {
      backdrop-filter: brightness(90%);
    }
  }

  img, svg {
    filter: ${({ filter }) => filter }
  }
`;

export const Nav = styled.nav`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      position: relative !important;
      vertical-align: top;
      display: inline-block;
      margin: 0 4px;
    }

    li:hover ul,
    ul li.menu-active ul {
      display: none;
      margin: auto;
    }

    li.menu-active {
      width: 100%;
    }

    .hover {
      background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
    }
  }

  a {
    ${({ theme }) => ({ ...theme.size.xxs })};
    display: block;
    max-height: 80px;
    color: ${({ theme }) => theme.colors.white};
    padding: 5px 4px 0;
    text-align: center;
    padding-bottom: ${p => p.theme.spacing.small};
  }

  a img {
    display: table;
    margin: 0 auto 3px auto;
    height: 40px;
  }

  @media screen and (max-width: 480px) {
    li {
      width: 22%;
    }

    a {
      font-size: 11px;
    }

    a img {
      width: 40%;
      height: auto;
    }
  }
`
