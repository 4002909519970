import React, { memo } from 'react'
import Container from 'src/components/Common/Container/index'
import Link from 'next/link'
import {
  CategoriesApi,
  CategoriesWithArea,
  ICategories,
  ICategory,
} from 'src/service/api/api.types'
import { getImageUrl } from 'src/utils/getImageUrl'
import theme from 'src/theme/theme'
import { Box, BoxProps, Nav, Wrapper } from './styles'

export type PresetCategory = keyof typeof presets

interface Props {
  categories?: CategoriesApi | ICategories
  preset?: PresetCategory
  customCategories?: Record<string, CategoriesWithArea>
}

function CategoryMenuComponent({ categories, preset, customCategories }: Props) {
  const presetStyles = presets[preset || 'primary']
  const [one, setOne] = React.useState<ICategory[]>()
  const [two, setTwo] = React.useState<ICategory[]>()

  React.useEffect(() => {
    if (categories && categories.data) {
      splitArray(categories.data)
    }
  }, [])

  const splitArray = (array: ICategory[]) => {
    const n = Math.round(array.length / 2)
    setOne(array.filter((item, index) => index <= n))
    setTwo(array.filter((item, index) => index > n))
  }

  return (
    <Wrapper>
      {!!one?.length && (
        <Box className="primary" {...presetStyles.one}>
          <Nav>
            <Container>
              <ul className="menu">
                {one?.map((item, index) => (
                  <li key={`${item.title} - ${index}`}>
                    <Link legacyBehavior passHref href={`/curso/${item.slug}`}>
                      <a>
                        <img alt="temp-alt" src={getImageUrl(item.iconUrl, 45, 45)} />
                        {item.title}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </Container>
          </Nav>
        </Box>
      )}
      {!!two?.length && (
        <Box className="secondary" {...presetStyles.two}>
          <Nav>
            <Container>
              <ul className="menu">
                {two?.map((item, index) => (
                  <li key={`${item.title} - ${index}`}>
                    <Link legacyBehavior passHref href={`/curso/${item.slug}`}>
                      <a>
                        <img alt="temp-alt" src={getImageUrl(item.iconUrl, 45, 45)} />
                        {item.title}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </Container>
          </Nav>
        </Box>
      )}
      {customCategories &&
        Object.keys(customCategories).length > 0 &&
        Object.keys(customCategories).map(categoryArea => (
          <Box
            className="secondary"
            {...presetStyles.two}
            key={categoryArea}
            backgroundColor={customCategories[categoryArea].area.color}
          >
            <Nav>
              <Container>
                <ul className="menu">
                  {customCategories[categoryArea].data.map((item, index) => (
                    <li key={`${item.title} - ${categoryArea} - ${index}`}>
                      <Link legacyBehavior passHref href={`/curso/${item.slug}`}>
                        <a>
                          <img alt="temp-alt" src={getImageUrl(item.iconUrl, 45, 45)} />
                          {item.title}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Container>
            </Nav>
          </Box>
        ))}
    </Wrapper>
  )
}

interface PresetsBox {
  [key: string]: {
    one: BoxProps
    two: BoxProps
  }
}

const presets: PresetsBox = {
  primary: {
    one: {
      color: theme.colors.white,
      backgroundColor: theme.colors.secondaryBackgroundColor,
    },
    two: {
      color: theme.colors.white,
      backgroundColor: theme.colors.primaryBackgroundColor,
    },
  },
  secondary: {
    one: {
      color: theme.colors.primaryTextColor,
      backgroundColor: theme.colors.palette.neutral300,
      filter:
        'brightness(0) saturate(100%) invert(16%) sepia(62%) saturate(327%) hue-rotate(172deg) brightness(93%) contrast(90%)',
    },
    two: {
      color: theme.colors.primaryTextColor,
      backgroundColor: theme.colors.palette.neutral400,
      filter:
        'brightness(0) saturate(100%) invert(16%) sepia(62%) saturate(327%) hue-rotate(172deg) brightness(93%) contrast(90%)',
    },
  },
  page404: {
    one: {
      color: theme.colors.white,
      backgroundColor: theme.colors.secondaryBackgroundColor,
    },
    two: {
      color: theme.colors.white,
      backgroundColor: theme.colors.secondaryBackgroundColor,
    },
  },
}

export const CategoryMenu = memo(CategoryMenuComponent)
