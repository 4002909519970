import React, { useEffect } from 'react'
import {
  BoxSearch,
  Form,
  SearchButton,
  WrapperInput,
} from 'src/components/Common/InputSearch/styles'
import { ImSearch } from 'react-icons/im'
import { colors } from 'src/theme/colors'
import { useRouter } from 'next/router'

interface Props {
  preset: InputPresets
  isIcon?: boolean
  placeholder?: string
  className?: string
  buttonClass?: string
}

export type InputPresets = keyof typeof presetsInput

function Input(props: Props) {
  const { buttonClass = 'buttonHeader' } = props
  const presetStyles = presetsInput[props.preset]
  const router = useRouter()
  const [search, setSearch] = React.useState('')
  const updateSearch = e => setSearch(e.target.value)

  useEffect(() => {
    if (router.query.search) {
      setSearch(router.query.search as string)
    }
  }, [])

  return (
    <Form action="/pesquisa">
      <BoxSearch {...presetStyles}>
        <input
          type="text"
          name="search"
          value={search}
          onChange={updateSearch}
          placeholder={props.placeholder ?? 'Pesquisar'}
          className={props.className}
        />
        <SearchButton className={buttonClass} type="submit">
          {props.isIcon ? <ImSearch /> : <span>Buscar</span>}
        </SearchButton>
      </BoxSearch>
    </Form>
  )
}

interface PresetsInput {
  [key: string]: WrapperInput
}

const presetsInput: PresetsInput = {
  primary: {
    color: colors.primaryTextColor,
    padding: '0.5rem',
    borderRadius: '0.3rem',
    backgroundColor: colors.palette.neutral450,
    borderColor: colors.palette.neutral450,
  },

  secondary: {
    color: colors.primaryTextColor,
    padding: '0.5rem',
    borderRadius: '0.3rem 0 0 0.3rem',
    backgroundColor: colors.background,
    borderColor: colors.palette.neutral450,
  },
}

export default Input
