import React, { FC } from 'react'
import useDeviceSize from 'src/hooks/useDeviceSize'
import { Squash as Hamburger } from 'hamburger-react'
import Link from 'next/link'
import Input from 'src/components/Common/InputSearch'
import Container from 'src/components/Common/Container'
import { Button } from 'src/components/Common/Button'
import { FaSearch } from 'react-icons/fa'
import MenuList from 'src/components/Common/MenuList'
import { colors } from 'src/theme/colors'
import ProfileHeader from 'src/components/Common/ProfileHeader'
import { useRouter } from 'next/router'
import {
  Content,
  InputWrapper,
  Nav,
  MenuHeader,
  LogoDesktop,
  LogoMobile,
  Grid,
  MobileButtonsWrapper,
} from './styles'

const MenuSticky: FC = () => {
  const [width] = useDeviceSize()
  const [open, setOpen] = React.useState(false)
  const [mobileSearch, setMobileSearch] = React.useState(false)
  const router = useRouter()
  let isMobile = width <= 768 && width !== 0
  const wantClass = open ? 'open' : 'close'
  const setClass = isMobile ? wantClass : ''
  const handleHamburger = () => setOpen(!open)

  React.useEffect(() => {
    isMobile = width <= 768
    if (open) {
      document.body.classList.add('stop-scrolling')
    }
    return () => document.body.classList.remove('stop-scrolling')
  }, [open])

  return (
    <MenuHeader>
      <Container>
        <Content className={isMobile && open ? 'mobile' : ''}>
          <div className="logo">
            <Link legacyBehavior passHref href="/">
              <a>
                <LogoDesktop
                  src="https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224794/logo-static_rkjkve.png"
                  alt="Logo Cordas e Música Projeto Musical"
                />
              </a>
            </Link>
          </div>

          <InputWrapper className={mobileSearch ? 'active' : ''}>
            <Input
              preset="secondary"
              placeholder="Digite aqui o assunto que está buscando..."
              isIcon
              buttonClass="buttonSticky"
            />
          </InputWrapper>

          <div className="backdrop" />
          {isMobile ? (
            <MobileButtonsWrapper>
              {router.asPath === '/' && (
                <Link
                  className="whats-app"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=5524988323449&text=Ol%C3%A1,%20acessei%20a%20p%C3%A1gina%20principal%20do%20site%20e%20preciso%20de%20ajuda!"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-label="WhatsApp"
                    role="img"
                    viewBox="0 0 512 512"
                  >
                    <rect width="512" height="512" rx="15%" fill="#25d366" />
                    <path
                      fill="#25d366"
                      stroke="#ffffff"
                      strokeWidth="26"
                      d="M123 393l14-65a138 138 0 1150 47z"
                    />
                    <path
                      fill="#ffffff"
                      d="M308 273c-3-2-6-3-9 1l-12 16c-3 2-5 3-9 1-15-8-36-17-54-47-1-4 1-6 3-8l9-14c2-2 1-4 0-6l-12-29c-3-8-6-7-9-7h-8c-2 0-6 1-10 5-22 22-13 53 3 73 3 4 23 40 66 59 32 14 39 12 48 10 11-1 22-10 27-19 1-3 6-16 2-18"
                    />
                  </svg>
                </Link>
              )}
              <Button
                onClick={() => setMobileSearch(!mobileSearch)}
                preset="transparent"
                hasIconAndText={false}
                LeftAccessory={<FaSearch size={20} color={colors.palette.neutral900} />}
              />
              <div className="button-hamburger" onClick={handleHamburger}>
                <Hamburger toggled={open} toggle={setOpen} size={20} distance="sm" />
              </div>
            </MobileButtonsWrapper>
          ) : null}

          <div className={`group ${setClass}`}>
            <Grid>
              <div className="logo">
                <Link legacyBehavior passHref href="/">
                  <a>
                    <LogoMobile
                      src="https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224794/logo-static_rkjkve.png"
                      alt="Logo Cordas e Música Projeto Musical"
                    />
                  </a>
                </Link>
              </div>

              <Nav>
                <MenuList />
              </Nav>

              <ProfileHeader />
            </Grid>
          </div>
        </Content>
      </Container>
    </MenuHeader>
  )
}
export default React.memo(MenuSticky)
