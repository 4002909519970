import styled from 'styled-components'

export const WhatsAppMenuWrapper = styled.li`
  display: flex !important;
  height: 100%;
  align-items: center;
  height: 40px !important;
  width: 40px !important;
  justify-content: center;

  a:hover,
  a:focus {
    border: none !important;
  }

  > a > svg {
    width: 40px;
    height: 40px;
  }
`
